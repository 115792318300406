import Food_King_1 from "../../assets/ProjectImages/Food_King_1.jpg";
import Food_King_2 from "../../assets/ProjectImages/Food_King_2.jpg";
import Food_King_3 from "../../assets/ProjectImages/Food_King_3.jpg";

import Fitzu_1 from "../../assets/ProjectImages/Fitzu_1.jpg";
import Fitzu_2 from "../../assets/ProjectImages/Fitzu_2.jpg";
import Fitzu_3 from "../../assets/ProjectImages/Fitzu_3.jpg";

import LadyGo_1 from "../../assets/ProjectImages/LadyGo_1.jpg";
import LadyGo_2 from "../../assets/ProjectImages/LadyGo_2.jpg";
import LadyGo_3 from "../../assets/ProjectImages/LadyGo_3.jpg";

import VUE_1 from "../../assets/ProjectImages/VUE_1.jpg";
import VUE_2 from "../../assets/ProjectImages/VUE_2.png";
import VUE_3 from "../../assets/ProjectImages/VUE_3.jpg";

import Blue_Words_1 from "../../assets/ProjectImages/Blue_Words_1.jpg";
import Blue_Words_2 from "../../assets/ProjectImages/Blue_Words_2.jpg";
import Blue_Words_3 from "../../assets/ProjectImages/Blue_Words_3.jpg";


import FitnessX_1 from "../../assets/ProjectImages/FitnessX_1.jpg";
import FitnessX_2 from "../../assets/ProjectImages/FitnessX_2.jpg";
import FitnessX_3 from "../../assets/ProjectImages/FitnessX_3.jpg";

import DeFixWallet_1 from "../../assets/ProjectImages/DeFixWallet_1.jpg";
import DeFixWallet_2 from "../../assets/ProjectImages/DeFixWallet_2.jpg";
import DeFixWallet_3 from "../../assets/ProjectImages/DeFixWallet_3.jpg";


const projects = [
    { name: "Food King",
      link: "https://www.figma.com/proto/VRQOvMgxxXcsbR8xbjhVkw/Gig_It?node-id=12-321&t=2i00FvXWg3Q51L4F-0&scaling=min-zoom&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=12%3A321&show-proto-sidebar=1",
      img: [Food_King_1, Food_King_2, Food_King_3] },
      
      { name: "Fitzu",
      link: "https://nikhil229685.invisionapp.com/console/share/MD31OTMAVW",
      img: [Fitzu_1, Fitzu_2, Fitzu_3] },
    
      { name: "LadyGo",
      link: "https://www.figma.com/proto/yLaoph0mt2kwYuSSYjOTGm/Taxi-APP?type=design&node-id=2-3877&t=86t1K1xWiw6NxNfO-0&scaling=scale-down&page-id=0%3A1&starting-point-node-id=2%3A3877",
      img: [LadyGo_1, LadyGo_2, LadyGo_3] },
      
      { name: "VUE",
      link: "https://www.figma.com/proto/ODTuCfhud00NthgqpKQTWz/Vue_App_2.0_211014?type=design&node-id=3998-26017&t=43r8d9xsPcaHoB4V-0&scaling=scale-down&page-id=1354%3A25332&starting-point-node-id=3998%3A26017&show-proto-sidebar=1",
      img: [VUE_1, VUE_2, VUE_3] },

      { name: "Blue Words",
      link: "https://www.figma.com/proto/9Tl5F9HWk0OBNSuvKK0tMs/blue-words?scaling=scale-down&content-scaling=fixed&page-id=0%3A1&node-id=1-2&starting-point-node-id=1%3A2&show-proto-sidebar=1",
      img: [Blue_Words_1, Blue_Words_2, Blue_Words_3] },

      { name: "FitnessX",
      link: "https://www.figma.com/proto/NxJaXjq9g8ShnstEAHOpNb/MSPL-fitness?type=design&node-id=2-9086&t=QKexpR4GLjMQeBvY-0&scaling=contain&page-id=0%3A1",
      img: [FitnessX_1, FitnessX_2, FitnessX_3] },

      { name: "DeFix Wallet",
      link: "https://www.figma.com/proto/R89QkuSNjl65NejQrHdU01/Defix-Wallet?type=design&node-id=0-3&t=WjUpwgEIq5jgAc1d-0&scaling=min-zoom&page-id=0%3A1",
      img: [DeFixWallet_1, DeFixWallet_2, DeFixWallet_3] },
  ];

  export default projects;