import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import staffAug from "../../../assets/staffAug.png"
import logo from "../../../assets/logo.svg"
import ftechizLogo from "../../../assets/ftechizLogo.png"
import handshake from "../../../assets/handshake.png"
import { animated } from 'react-spring/renderprops'


import "./staffAug.scss";

export default class StaffAugmentation extends Component{   
    render(){
        return (
            <div className='staffAug'>
            <div className='dataBox'>
                <div className='title'>
                    <h1>AMTech & Ftechiz Solutions Collaboration</h1>
                    <h2>Enhancing Your Workforce</h2>
                </div>
                <div class="collab">
            <div class="div">
                <img src={logo} alt="" />
            </div>
            <div>
                <img src={handshake} alt="" className="hand"/>
            </div>
            <div class="div">
              <a href='https://ftechiz.com/' target="_blank"><img src={ftechizLogo} alt="" /></a>  
            </div>
        </div>
                <div className='About'>
                    <div className='contentBox'>
                        <h3>Welcome to AMTech's Team Expansion Services</h3>
                        <p>At AMTech, we are committed to delivering top-notch technology solutions that drive your business
                            forward. To accomplish this, we have partnered with Ftechiz Solutions to provide
                            unparalleled steam expansion services. This collaboration is designed to help businesses scale
                            their operations efficiently and effectively with the right talent.</p>
        
                    </div>
                    <div className='img'>
                        <img src={staffAug} alt='Team Expansion' />
        
                    </div>
        
                </div>
                <div className='AboutFtechiz'>
                    <div className='contentBox'>
                        <h3>About Ftechiz Solutions</h3>
                        <p>Ftechiz Solutions is a renowned provider of team expansion services, specializing in delivering
                            highly skilled professionals to meet the diverse needs of businesses across various industries. With
                            a focus on quality, reliability, and expertise, Ftechiz Solutions has established itself as a
                            trusted partner for companies seeking to enhance their workforce.</p>
                            <a href='https://ftechiz.com/' target="_blank"> <button class="btn-main">Visit Ftechiz Website</button></a>

                    </div>        
                </div>                
        
                <div className='AboutFtechiz'>
                    <div className='contentBox'>
                        <h3>What is Team Expansion?</h3>
                        <p>Team Expansion is a strategic approach to hiring that allows companies to add skilled
                            professionals to their existing teams on a temporary or permanent basis. This model offers
                            flexibility and scalability, enabling businesses to quickly adapt to changing market demands and
                            project requirements.
                        </p>
                    </div>        
                </div>
        
                <div className='augCards'>
                    <h3>Benefits of Team Expansion:</h3>
                    <div className='contentBox'>
                        <div className='card'>
                            <h4>Access to Specialized Skills</h4>
                            <p>
                                Gain access to a pool of highly skilled professionals with expertise in various domains.
                            </p>
                        </div>
                        <div className='card'>
                            <h4>Cost-Effective</h4>
                            <p>
                                Reduce overhead costs associated with full-time hiring and training.
                            </p>
                        </div>
                        <div className='card'>
                            <h4>Flexibility</h4>
                            <p>
                                Scale your workforce up or down based on project needs and business demands.
                            </p>
                        </div>
                        <div className='card'>
                            <h4>Quick Onboarding</h4>
                            <p>
                                Speed up the hiring process and get the right talent on board swiftly.
                            </p>
                        </div>
                        <div className='card'>
                            <h4>Focus on Core Business</h4>
                            <p>
                                Allow your in-house team to focus on core business activities while augmented staff handles
                                specific tasks.
                            </p>
                        </div>
                    </div>
                </div>
        
                <div className='augCards'>
                    <h3>Why Choose AMTech and Ftechiz Solutions?</h3>
                    <div className='contentBox box2'>
                        <div className='card'>
                            <h4>Expertise and Experience</h4>
                            <p>
                                With years of experience in the industry, both AMTech and Ftechiz Solutions bring a wealth of
                                knowledge and expertise to the table.
                            </p>
                        </div>
                        <div className='card'>
                            <h4>Tailored Solutions</h4>
                            <p>
                                We understand that every business is unique, and we provide customized staffing solutions to
                                meet your specific requirements.
                            </p>
                        </div>
                        <div className='card'>
                            <h4>Quality Assurance</h4>
                            <p>
                                Our rigorous selection process ensures that you get only the best talent for your projects.
                            </p>
                        </div>
                        <div className='card'>
                            <h4>Customer-Centric Approach</h4>
                            <p>
                                We prioritize your business goals and work closely with you to achieve them.
                            </p>
                        </div>
                    </div>
                </div>

                <div className='AboutFtechiz'>
                    <div className='contentBox'>
                        <h2>Get Started Today</h2>
                        <p>Empower your business with the right talent through our Team Expansion services. Contact us today to learn more about how MTech and Ftechiz Solutions can help you achieve your business objectives.
                        </p>
                       <Link to="/contact-us"><button class="btn-main">Contact Now</button></Link> 
                    </div>        
                </div>       

            </div>
        </div>
        )
    }

}