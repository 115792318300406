import HomeScreen1Component from "./screen1/homeScreen1";
import HomeScreen2Component from "./screen2/homeScreen2";

export const SCREEN_LIST = [
  {
    id: 0,
    component: HomeScreen1Component,
    classes: ''
  }, {
    id: 1,
    component: HomeScreen2Component,
    classes: ''
  }
];