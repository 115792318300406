import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListIconImage from '../../../../assets/custom-tick.svg'; 

export default class ProjectManagementComponent extends Component {
  render() {
    const { subItem } = this.props;
    if (subItem) {
      return (
        <div id={subItem.id} data-title={subItem.displayText} className='subItem-container'>
          <h2 className='des-title'>{subItem.displayText}</h2>
          <div className='des-text-container'>
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Proactive Management: </span>
                <span>Ensuring timely and budget-friendly delivery.</span>
              </div>
            </div>  
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Collaborative Approach: </span>
                <span>Making us great partners and fun to work with.</span>
              </div>
            </div>  
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Business Alignment: </span>
                <span>Aligning solutions with your business and customer needs for maximum impact.</span>
              </div>
            </div>         
          </div>
        </div>
      );  
    }

    return null;
  }
}

ProjectManagementComponent.propTypes = {
  subItem: PropTypes.object.isRequired
};
