import AutomatedPowerControlComponent from '../projectDetail/automatedPowerControl/automatedPowerControl';
import CleanRoomControlComponent from '../projectDetail/cleanRoomControl/cleanRoomControl';
import CustomIntegratedDisplayComponent from '../projectDetail/customIntegratedDisplay/customIntegratedDisplay';
import MidiControlledReactiveLightingComponent from '../projectDetail/midiControlledReactiveLighting/midiControlledReactiveLighting';
import WirelessEmbeddedSolutionComponent from '../projectDetail/wirelessEmbeddedSolution/wirelessEmbeddedSolution';
import FtechizProjectsComponent from '../projectDetail/ftechizProjects/ftechizProjects';
import BackgroundImage from '../../../assets/compressed/amtech-operations.jpg';
import { PROJECTS } from "../../router/routes.const";
import ccsImage from '../../../assets/compressed/cleanRoomControlBoard.jpg';
import scrImage from '../../../assets/compressed/amtech-operations.jpg';
import pwrImage from '../../../assets/compressed/automatedPowerControl.jpg';
import midiImage from '../../../assets/compressed/midiControllerWorking.jpg';
import internet from '../../../assets/compressed/InternetOfThings.jpg';

// Note:- The id and the end url in the link should have the same text
// All the fields given in the JSON object are mandatory


export const PROJECT_LIST = [
  {
    id: 'ftechiz-apps',
    displayText: 'Mobile App Projects',
    backgroundImage: ccsImage,
    link: `${PROJECTS}/ftechiz-projects`,
    detailsComponent: FtechizProjectsComponent,
    subList: [
      {
        id: 'about',
        displayText: 'UI-UX Journey',
        link: `${PROJECTS}/ftechiz-projects`
      },
      {
        id: 'food-king',
        displayText: 'Food King',
        link: `${PROJECTS}/ftechiz-projects`
      },
      {
        id: 'fitzu',
        displayText: 'Fitzu',
        link: `${PROJECTS}/ftechiz-projects`
      },
      {
        id: 'ladygo',
        displayText: 'LadyGo',
        link: `${PROJECTS}/ftechiz-projects`
      },
      {
        id: 'vue',
        displayText: 'VUE',
        link: `${PROJECTS}/ftechiz-projects`
      },
      {
        id: 'blue-words',
        displayText: 'Blue Words',
        link: `${PROJECTS}/ftechiz-projects`
      },
      {
        id: 'fitness-x',
        displayText: 'FitnessX',
        link: `${PROJECTS}/ftechiz-projects`
      },
      {
        id: 'defix-wallet',
        displayText: 'DeFix Wallet',
        link: `${PROJECTS}/ftechiz-projects`
      },
    ]
  },
  {
    id: 'wireless-embedded-solution',
    displayText: 'Wireless Embedded Solution',
    backgroundImage: internet,
    link: `${PROJECTS}/wireless-embedded-solution`,
    detailsComponent: WirelessEmbeddedSolutionComponent,
    subList: [
      {
        id: 'fun-concept',
        displayText: 'Easy to use Dashboard',
        link: `${PROJECTS}/wireless-embedded-solution`
      },
      {
        id: 'stage-lighting',
        displayText: 'Secure data & operations',
        link: `${PROJECTS}/wireless-embedded-solution`
      },
      {
        id: 'stage-lighting',
        displayText: 'Rapid integration for new designs',
        link: `${PROJECTS}/wireless-embedded-solution`
      },
      {
        id: 'stage-lighting',
        displayText: 'Fully integrated hardware + software IoT platform',
        link: `${PROJECTS}/wireless-embedded-solution`
      }
    ]
  },
  {
    id: 'clean-room-control',
    displayText: 'Clean Room Control and Monitoring System',
    backgroundImage: ccsImage,
    link: `${PROJECTS}/clean-room-control`,
    detailsComponent: CleanRoomControlComponent,
    subList: [
      {
        id: 'automated-airspeed-control',
        displayText: 'Automated air speed control',
        link: `${PROJECTS}/clean-room-control`
      },
      {
        id: 'filtration-monitoring',
        displayText: 'Air filtration monitoring',
        link: `${PROJECTS}/clean-room-control`
      },
      {
        id: 'compliance',
        displayText: 'Class I and Class II compliance',
        link: `${PROJECTS}/clean-room-control`
      }
    ]
  },
  {
    id: 'custom-integrated-display',
    displayText: 'Custom Integrated Display',
    backgroundImage: scrImage,
    link: `${PROJECTS}/custom-integrated-display`,
    detailsComponent: CustomIntegratedDisplayComponent,
    subList: [
      {
        id: 'visual-control',
        displayText: 'Visual control system for the Clean Room Control System',
        link: `${PROJECTS}/custom-integrated-display`
      },
      {
        id: 'touch-screen',
        displayText: 'Touch screen and custom graphics',
        link: `${PROJECTS}/custom-integrated-display`
      }
    ]
  },
  {
    id: 'automated-power-control',
    displayText: 'Automated Power Control and Monitoring',
    backgroundImage: pwrImage,
    link: `${PROJECTS}/automated-power-control`,
    detailsComponent: AutomatedPowerControlComponent,
    subList: [
      {
        id: 'power-monitoring',
        displayText: 'Power monitoring for an energy utility',
        link: `${PROJECTS}/automated-power-control`
      },
      {
        id: 'hv-specifications',
        displayText: 'High power/high voltage specifications',
        link: `${PROJECTS}/automated-power-control`
      },
      {
        id: 'ul-compliance',
        displayText: 'Regulatory Compliance',
        link: `${PROJECTS}/automated-power-control`
      }
    ]
  },
  {
    id: 'midi-controlled-reactive-lighting',
    displayText: 'PROTOTYPE: MIDI-Controlled Reactive Lighting',
    backgroundImage: midiImage,
    link: `${PROJECTS}/midi-controlled-reactive-lighting`,
    detailsComponent: MidiControlledReactiveLightingComponent,
    subList: [
      {
        id: 'fun-concept',
        displayText: 'Proof of concept design project',
        link: `${PROJECTS}/midi-controlled-reactive-lighting`
      },
      {
        id: 'stage-lighting',
        displayText: 'Interactive stage performance lighting',
        link: `${PROJECTS}/midi-controlled-reactive-lighting`
      },
      // {
      //   id: 'concerts',
      //   displayText: 'Makes exciting concerts',
      //   link: `${PROJECTS}/midi-controlled-reactive-lighting`
      // }
    ]
  },
];