import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListIconImage from '../../../../assets/custom-tick.svg'; 
import frontendImage from '../../../../assets/fitnessApp.png';

export default class FrontendDesignComponent extends Component {
  render() {
    const { subItem } = this.props;
    if (subItem) {
      return (
        <div id={subItem.id} data-title={subItem.displayText} className='subItem-container'>
          <h2 className='des-title'>{subItem.displayText}</h2>
          <div className='des-text-container'>
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Expert Engineering: </span>
                <span>Crafting visually stunning, user-friendly interfaces.</span>
              </div>
            </div>  
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Seamless Integration: </span>
                <span>Enhancing user experience with integration into existing systems.</span>
              </div>
            </div>
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Responsive Design </span>
                <span>Ensuring optimal performance across all devices.</span>
              </div>
            </div> 
            <div className='in-line-image-container'>
              <img alt={'fitnessAppExample'} src={frontendImage} className='details-image-in-line' />
            </div>         
          </div>
        </div>
      );  
    }

    return null;
  }
}

FrontendDesignComponent.propTypes = {
  subItem: PropTypes.object.isRequired
};
