import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Header from "../../../components/header/header";
import { DEFAULT, PROJECTS } from '../../../router/routes.const';
import IoTImage from '../../../../assets/compressed/iot.png';
import ListIconImage from '../../../../assets/custom-tick.svg';
import  projects  from '../../../utils/projects';
// Images 



import './ftechizProjects.scss';

export default class FtechizProjectsComponent extends Component {
  
  
  getBreadcrumbs = () => {
    const { project } = this.props;
    if (project) {
      return (
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-container'>
          <Link className="breadcrumb" to={DEFAULT}>Home</Link>
          <Link className="breadcrumb" to={PROJECTS} onClick={this.props.backButtonClickHandler}>Projects</Link>
          <Typography className='breadcrumb'>{project.displayText}</Typography>
        </Breadcrumbs>
      );
    }
  }
  
  render() {
    const image = "../../../../assets/ProjectImages/";
    const { project, backButtonClickHandler, scrollDirection } = this.props;
    if (project) {
      return (
        <div className='project-details-container'>
          <Header className='project-details-header' title={project.displayText} breadcrumbs={this.getBreadcrumbs()} rootEl={'#' + project.id + '-content'} />
          <Container className='details-content'>
            <div className={`back-button-container ${scrollDirection === 'up' ? 'show' : 'hide'}`}>
              <Button className={`back-button `} startIcon={<KeyboardArrowLeftIcon className='am-icon' />} onClick={backButtonClickHandler.bind(this)}>
                Back
              </Button>
            </div>
            <div className='subItem-container'>
            <div className='automated-power-control-text'>
                <div className='projectItem'>
                    <div>
                        <span className='highlighted-text'>UI/UX Journey</span>
                    </div>
                    <div>
                        Ftechiz Solutions Pvt Ltd boasts an impressive portfolio of
                        UI/UX projects that exemplify their commitment to innovation
                        and user-centric design. From sleek mobile applications to
                        intuitive web interfaces, Ftechiz has demonstrated its prowess
                        in crafting engaging digital experiences across various
                        platforms.
                    </div>
                </div>
                {projects.map((project, index) => (
        <div className="projectItem" key={index}>
          <span className='list-icon-container'>
            <img alt='list' src={ListIconImage} className='list-icon' />
          </span>
          <span className='highlighted-text'>{project.name}</span> <br />
          <div className="gallery">
            {project.img.map((imageSrc, imgIndex) => (
              <img key={imgIndex} src={imageSrc} alt={`${project.name} ${imgIndex}`} />
            ))}
          </div>
          <a className='view-design' href={project.link} target="_blank" rel="noopener noreferrer">
            <button class="btn-main">{project.link ? "View Our Design" : "Design not available"}</button>
          </a>
        </div>
      ))}
            </div>
            </div>
          </Container>
        </div>
      );
    }

    return null;
  }
}

FtechizProjectsComponent.propTypes = {
  project: PropTypes.object.isRequired,
  backButtonClickHandler: PropTypes.func
};
