import DesignEngineeringServicesComponent from '../serviceDetail/designEngineeringService/designEngineeringService';
import HardwareDesignComponent from '../serviceDetail/designEngineeringService/hardwareDesign';
import FirmwareDesignComponent from '../serviceDetail/designEngineeringService/firmwareDesign';
import RetrofittingComponent from '../serviceDetail/designEngineeringService/retrofitting';
import ProductEngineeringSupportComponent from '../serviceDetail/designEngineeringService/productEngineeringSupport';
import DocumentationComponent from '../serviceDetail/designEngineeringService/documentation';
import DevelopmentProcessComponent from '../serviceDetail/developmentProcess/developmentProcess';
import IndustrialSolutionsComponent from '../serviceDetail/industrialSolutions/industrialSolutions';
import ManufacturingServicesComponent from '../serviceDetail/manufacturingServices/manufacturingServices';
import BackgroundImage from '../../../assets/compressed/amtech-operations.jpg';
import { SERVICES } from "../../router/routes.const";
import desImage from '../../../assets/compressed/designEngineeringService.jpg';
import devImage from '../../../assets/compressed/amtech-operations.jpg';
import indImage from '../../../assets/compressed/industrialSolutions.jpg';
import manImage from '../../../assets/compressed/manufacturing1.jpg';
// Note:- The id and the end url in the link should have the same text
// All the fields given in the JSON object are mandatory

import WebAndAppServicesComponent from '../serviceDetail/webAndAppService/webAndAppServices';
import FrontendDesignComponent from '../serviceDetail/webAndAppService/frontendDesign';
import BackendDesignComponent from '../serviceDetail/webAndAppService/backendDesign';
import MobileAppsComponent from '../serviceDetail/webAndAppService/mobileApps';
import ProjectManagementComponent from '../serviceDetail/webAndAppService/projectManagement';
import ProductDesignComponent from '../serviceDetail/webAndAppService/productDesign';

export const SERVICE_LIST = [
  {
    id: 'web-and-app-design',
    displayText: 'Web and App Design',
    backgroundImage: desImage,
    link: `${SERVICES}/web-and-app-design`,
    detailsComponent: WebAndAppServicesComponent,
    subList: [
      {
        id: 'front-end',
        displayText: 'Front End',
        link: `${SERVICES}/web-and-app-design#front-end`,
        component: FrontendDesignComponent
      },
      {
        id: 'back-end',
        displayText: 'Back End',
        link: `${SERVICES}/web-and-app-design#back-end`,
        component: BackendDesignComponent
      },
      {
        id: 'mobile-apps',
        displayText: 'Mobile App Development',
        link: `${SERVICES}/web-and-app-design#mobile-apps`,
        component: MobileAppsComponent
      },
      {
        id: 'project-management',
        displayText: 'Project Management',
        link: `${SERVICES}/web-and-app-design#project-management`,
        component: ProjectManagementComponent
      },
      {
        id: 'product-design',
        displayText: 'Product Design',
        link: `${SERVICES}/web-and-app-design#product-design`,
        component: ProductDesignComponent
      }
    ]
  },
  {
    id: 'design-engineering-services',
    displayText: 'Design Engineering Services',
    backgroundImage: desImage,
    link: `${SERVICES}/design-engineering-services`,
    detailsComponent: DesignEngineeringServicesComponent,
    subList: [
      {
        id: 'hardware-design',
        displayText: 'Hardware Design',
        link: `${SERVICES}/design-engineering-services#hardware-design`,
        component: HardwareDesignComponent
      },
      {
        id: 'firmware-design',
        displayText: 'Firmware Design',
        link: `${SERVICES}/design-engineering-services#firmware-design`,
        component: FirmwareDesignComponent
      },
      {
        id: 'retrofitting',
        displayText: 'Retrofitting',
        link: `${SERVICES}/design-engineering-services#retrofitting`,
        component: RetrofittingComponent
      },
      {
        id: 'product-engineering-support',
        displayText: 'Product Engineering Support',
        link: `${SERVICES}/design-engineering-services#product-engineering-support`,
        component: ProductEngineeringSupportComponent
      },
      {
        id: 'documentation',
        displayText: 'Documentation',
        link: `${SERVICES}/design-engineering-services#documentation`,
        component: DocumentationComponent
      }
    ]
  },
  {
    id: 'development-process',
    displayText: 'Development Process',
    backgroundImage: devImage,
    link: `${SERVICES}/development-process`,
    detailsComponent: DevelopmentProcessComponent,
    subList: [
      {
        id: 'work-with-you',
        displayText: 'Design Reviews',
        link: `${SERVICES}/development-process`
      },
      {
        id: 'jump-in-design',
        displayText: 'Complete partial designs',
        link: `${SERVICES}/development-process`
      },
      {
        id: 'acceptance-testing',
        displayText: 'Acceptance Testing',
        link: `${SERVICES}/development-process`
      },
      {
        id: 'project-management',
        displayText: 'Project Management',
        link: `${SERVICES}/development-process`
      }
    ]
  },
  {
    id: 'industrial-solutions',
    displayText: 'Industrial Solutions',
    backgroundImage: indImage,
    link: `${SERVICES}/industrial-solutions`,
    detailsComponent: IndustrialSolutionsComponent,
    subList: [
      {
        id: 'understanding-requirements',
        displayText: 'Small- or Large-Scale',
        link: `${SERVICES}/industrial-solutions`
      },
      {
        id: 'repeatability',
        displayText: 'Repeatability & Reliability',
        link: `${SERVICES}/industrial-solutions`
      },
      {
        id: 'bom',
        displayText: 'BOM implementation and sourcing',
        link: `${SERVICES}/industrial-solutions`
      },
      {
        id: 'tooling',
        displayText: 'Tooling',
        link: `${SERVICES}/industrial-solutions`
      }
    ]
  },
  {
    id: 'manufacturing-services',
    displayText: 'Manufacturing Services',
    backgroundImage: manImage,
    link: `${SERVICES}/manufacturing-services`,
    detailsComponent: ManufacturingServicesComponent,
    subList: [
      {
        id: 'new-existing-services',
        displayText: 'Manufacture new or existing designs',
        link: `${SERVICES}/manufacturing-services`
      },
      {
        id: 'scm',
        displayText: 'Supply Chain Management',
        link: `${SERVICES}/manufacturing-services`
      },
      {
        id: 'turn-key-assembly',
        displayText: 'Turn-key Assembly',
        link: `${SERVICES}/manufacturing-services`
      }
    ]
  }

];