import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListIconImage from '../../../../assets/custom-tick.svg'; 
import BackendGraphic from '../../../../assets/backendGrapic.png'
export default class BackendDesignComponent extends Component {
  render() {
    const { subItem } = this.props;
    if (subItem) {
      return (
        <div id={subItem.id} data-title={subItem.displayText} className='subItem-container'>
          <h2 className='des-title'>{subItem.displayText}</h2>
          <div className='des-text-container'>
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Robust Solutions: </span>
                <span>Developing scalable and secure back-end systems.</span>
              </div>
            </div>  
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Quality Control: </span>
                <span>Ensuring secure and efficient operations through rigorous testing.</span>
              </div>
            </div> 
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Flexible Architecture </span>
                <span>Enabling easy integration with both legacy systems and new products.</span>
              </div>
            </div>  
            <div className='in-line-image-container2'>
              <img alt={'fitnessAppExample'} src={BackendGraphic} className='details-image-in-line' />
            </div>       
          </div>
        </div>
      );  
    }

    return null;
  }
}

BackendDesignComponent.propTypes = {
  subItem: PropTypes.object.isRequired
};
