import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListIconImage from '../../../../assets/custom-tick.svg'; 
import ProductDesignGraphic from '../../../../assets/productDesignGraphic.png'

export default class ProductDesignComponent extends Component {
  render() {
    const { subItem } = this.props;
    if (subItem) {
      return (
        <div id={subItem.id} data-title={subItem.displayText} className='subItem-container'>
          <h2 className='des-title'>{subItem.displayText}</h2>
          <div className='des-text-container'>
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Innovative Designs: </span>
                <span>Creating products that resonate with end-users and meet business objectives.</span>
              </div>
            </div>  
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Expert Team: </span>
                <span>Capable of creating new products and enhancing existing ones.</span>
              </div>
            </div>   
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Comprehensive Approach: </span>
                <span>From conceptualization to implementation, ensuring top-tier quality.</span>
              </div>
            </div>
            <div className='in-line-image-container2'>
              <img alt={'fitnessAppExample'} src={ProductDesignGraphic} className='details-image-in-line' />
            </div>        
          </div>
        </div>
      );  
    }

    return null;
  }
}

ProductDesignComponent.propTypes = {
  subItem: PropTypes.object.isRequired
};
