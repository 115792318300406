import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ListIconImage from '../../../../assets/custom-tick.svg'; 

export default class MobileAppsComponent extends Component {
  render() {
    const { subItem } = this.props;
    if (subItem) {
      return (
        <div id={subItem.id} data-title={subItem.displayText} className='subItem-container'>
          <h2 className='des-title'>{subItem.displayText}</h2>
          <div className='des-text-container'>
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Cutting-Edge Apps: </span>
                <span>Designing for both iOS and Android platforms.</span>
              </div>
            </div>  
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>Native & Cross-Platform: </span>
                <span>Excelling in broad reach development.</span>
              </div>
            </div>
            <div className='des-text'>
              <div className='list-icon-container'>
                <img alt='list' src={ListIconImage} className='list-icon'/>
              </div>
              <div>
                <span className='highlighted-text'>User-Focused: </span>
                <span>Ensuring intuitive navigation and superior user engagement.</span>
              </div>
            </div>           
          </div>
        </div>
      );  
    }

    return null;
  }
}

MobileAppsComponent.propTypes = {
  subItem: PropTypes.object.isRequired
};
